import React from "react";

const Loading1 = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
    >
      <lottie-player
        src="https://assets3.lottiefiles.com/packages/lf20_b88nh30c.json"
        background="transparent"
        speed="1"
        style={{ width: "250px", height: "250px" }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
};

export default Loading1;
